import React, { useState } from "react";
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBDropdownLink,
  MDBCollapse,
  MDBRipple,
} from "mdb-react-ui-kit";

import logo from "../assets/logo/logo-probotus-blue-large.png";

export default function Navbar() {
  const [showBasic, setShowBasic] = useState(false);

  return (
    <MDBNavbar fixed="top" expand="lg" light bgColor="light">
      <MDBContainer className="text-center">
        <MDBNavbarBrand href="/">
          <img src={logo} alt="Probotus" height="32" />
        </MDBNavbarBrand>

        <MDBNavbarToggler
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => setShowBasic(!showBasic)}
        >
          <MDBIcon icon="bars" fas />
        </MDBNavbarToggler>

        <MDBCollapse navbar show={showBasic}>
          <MDBNavbarNav className="mb-2 mb-lg-0 justify-content-end">
            <MDBNavbarItem className="mx-2">
              <MDBRipple>
                <MDBNavbarLink active aria-current="page" href="/" className="">
                  <strong>HOME</strong>
                </MDBNavbarLink>
              </MDBRipple>
            </MDBNavbarItem>

            <MDBNavbarItem className="mx-2">
              <MDBDropdown>
                <MDBRipple>
                  <MDBDropdownToggle tag="a" className="nav-link">
                    <strong>PRODUCTS</strong>
                  </MDBDropdownToggle>
                </MDBRipple>
                <MDBDropdownMenu className="mt-2" style={{ zIndex: '1080'}}>
                  <MDBDropdownItem>
                    <MDBDropdownLink>Coming Soon</MDBDropdownLink>
                  </MDBDropdownItem>
                  <MDBDropdownItem>
                    <MDBDropdownLink>Coming Soon</MDBDropdownLink>
                  </MDBDropdownItem>
                  <MDBDropdownItem>
                    <MDBDropdownLink>Coming Soon</MDBDropdownLink>
                  </MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavbarItem>


            <MDBNavbarItem className="ms-2">
              <MDBRipple>
                <MDBNavbarLink href="/careers" className="">
                  <strong>CAREERS</strong>
                </MDBNavbarLink>
              </MDBRipple>
            </MDBNavbarItem>
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBContainer>
    </MDBNavbar>
  );
}
