import React from "react";
import {
  MDBFooter,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
} from "mdb-react-ui-kit";

import logo from "../assets/logo/logo-probotus-blue-small.png";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <MDBFooter
      style={{ backgroundColor: "#1C2331" }}
      className="text-white text-center text-lg-left"
    >
      <MDBContainer className="p-4">
        <MDBRow>
          <MDBCol lg="5" md="12" className="mb-4 mb-md-0">
            <h5 className="text-uppercase text-lg-start">
              <a href="/">
                <img src={logo} className="mb-1" alt="PROBOTUS" height="30" />
              </a>
            </h5>
            <hr
              className="text-warning mb-3 mt-0 d-inline-block d-lg-none d-xl-none"
              style={{ width: "60px" }}
            />
            <hr
              className="text-warning mb-3 mt-0 d-none d-lg-block"
              style={{ width: "60px" }}
            />

            <p className="text-lg-start">
              We are the professional in service robots in the USA. Driven by
              innovation in machine learning, artificial intelligence, computer
              vision and speech recognition, we can provide semi autonomous and
              fully autonomous robots for commercial service market.
            </p>
          </MDBCol>

          <MDBCol lg="1" md="0"></MDBCol>

          <MDBCol lg="3" md="6" className="mb-4 mb-md-0">
            <h5 className="text-uppercase text-lg-start">Links</h5>
            <hr
              className="text-warning mb-3 mt-0 d-inline-block d-lg-none d-xl-none"
              style={{ width: "60px" }}
            />
            <hr
              className="text-warning mb-3 mt-0 d-none d-lg-block"
              style={{ width: "60px" }}
            />

            <ul className="list-unstyled mb-0">
              <li className="mb-3 text-lg-start">
                <a href="#!" className="text-white">
                  Probotus
                  <MDBIcon className="ms-2" fas icon="globe-americas" />
                </a>
              </li>
              <li className="mb-3 text-lg-start">
                <a href="#!" className="text-white">
                  Facebook <MDBIcon className="ms-2" fab icon="facebook" />
                </a>
              </li>
              <li className="mb-3 text-lg-start">
                <a href="#!" className="text-white">
                  Youtube <MDBIcon className="ms-2" fab icon="youtube" />
                </a>
              </li>
            </ul>
          </MDBCol>

          <MDBCol lg="3" md="6" className="mb-4 mb-md-0">
            <h5 className="text-uppercase text-lg-start">Contact</h5>
            <hr
              className="text-warning mb-3 mt-0 d-inline-block d-lg-none d-xl-none"
              style={{ width: "60px" }}
            />
            <hr
              className="text-warning mb-3 mt-0 d-none d-lg-block"
              style={{ width: "60px" }}
            />

            <ul className="list-unstyled">
              <li className="mb-3 text-lg-start d-none">
                <a href="#!" className="text-white">
                  <MDBIcon className="me-2" fas icon="phone" /> 1-800-233-1548
                </a>
              </li>
              <li className="mb-3 text-lg-start">
                <a href="#!" className="text-white">
                  <MDBIcon className="me-2" fas icon="envelope" />{" "}
                  support@probotus.com
                </a>
              </li>
              <li className="mb-3 text-lg-start">
                <a href="/" className="text-white">
                  <MDBIcon className="me-2" fas icon="globe" /> www.probotus.com
                </a>
              </li>
            </ul>
          </MDBCol>
        </MDBRow>
      </MDBContainer>

      <div
        className="text-center p-3 text-muted"
        style={{ backgroundColor: "#161C27" }}
      >
        &copy; {new Date().getFullYear()} Probotus Inc. <Link to="/privacy" style={{ textDecoration: 'none'}}>Privacy Policy</Link>.
      </div>
    </MDBFooter>
  );
};

export default Footer;