import React, { Suspense, lazy, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AOS from 'aos';
import "aos/dist/aos.css";


import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import LoadingScreen from './Components/LoadingScreen'

const NotFound = lazy(() => import('./Components/Pages/NotFound'))
const Home = lazy(() => import('./Components/Home/Home'))
const PrivacyPolicy = lazy(() => import('./Components/Pages/PrivacyPolicy'))
const Products = lazy(() => import('./Components/Pages/Products'))
const Careers = lazy(() => import('./Components/Pages/Careers'))


function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      offset: 100,
      once: true,
    });
    AOS.refresh();
  }, []);


  let routes;

  routes = (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/privacy" element={<PrivacyPolicy />} />
      <Route path="/products" element={<Products />} />
      <Route path="/careers" element={<Careers />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );

  return (
    <React.Fragment>
      <Router>
        <Navbar />
        <Suspense fallback={LoadingScreen()}>{routes}</Suspense>
        <Footer />
      </Router>
    </React.Fragment>
  );
}

export default App;
