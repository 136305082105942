import React from 'react';
import { MDBSpinner, MDBContainer } from 'mdb-react-ui-kit';

const LoadingScreen = () => {
  return (
    <MDBContainer className="text-center">
      <br /><br /><br /><br /><br /><br />
      <MDBSpinner role="status" color="primary" className="my-5">
        <span className="visually-hidden">Loading...</span>
      </MDBSpinner>
      <br /><br /><br /><br /><br /><br />
    </MDBContainer>
  );
};

export default LoadingScreen;
